<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.currency')"
      filterable
      :size="size"
      class="w-100"
      clearable
    >
      <el-option
        v-for="(currency, index) in currencies"
        :key="'currencies-' + index"
        :label="currency.symbol"
        :value="currency.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    board_id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      currency_symbol: '',
    };
  },
  async mounted() {
    if (this.currencies && this.currencies.length === 0) {
      await this.updateInventory()
    }
  },
  computed: {
    ...mapGetters({
      currencies: "currency/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "currency/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
