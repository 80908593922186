<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.new_m", {
                m: $t("message.product"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.name')"
                    class="label_mini"
                    prop="name"
                  >
                    <el-input
                      :placeholder="$t('message.name')"
                      v-model="form.name"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.code')"
                    class="label_mini"
                    prop="code"
                  >
                    <el-input
                      :placeholder="$t('message.code')"
                      v-model="form.code"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.buy_price')"
                    class="label_mini"
                    prop="buy_price"
                  >
                    <el-input
                      type="number"
                      :placeholder="$t('message.buy_price')"
                      v-model="form.buy_price"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.buy_price_currency_id')"
                    class="label_mini"
                    prop="buy_price_currency_id"
                  >
                    <select-currency
                      ref="buyPriceCurrency"
                      :size="'medium'"
                      :placeholder="columns.buy_price_currency_id.title"
                      :id="form.buy_price_currency_id"
                      v-model="form.buy_price_currency_id"
                    >
                    </select-currency>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.selling_price')"
                    class="label_mini"
                    prop="selling_price"
                  >
                    <el-input
                      type="number"
                      :placeholder="$t('message.selling_price')"
                      v-model="form.selling_price"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.selling_price_currency_id')"
                    class="label_mini"
                    prop="selling_price_currency_id"
                  >
                    <select-currency
                      ref="sellingPriceCurrency"
                      :size="'medium'"
                      :placeholder="columns.selling_price_currency_id.title"
                      :id="form.selling_price_currency_id"
                      v-model="form.selling_price_currency_id"
                    >
                    </select-currency>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.measurement_id')"
                    class="label_mini"
                    prop="measurement_id"
                  >
                    <select-measurement
                      :size="'medium'"
                      :placeholder="columns.measurement_id.title"
                      :id="form.measurement_id"
                      v-model="form.measurement_id"
                    >
                    </select-measurement>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.packages')"
                    class="label_mini"
                    prop="packages"
                  >
                    <select-packages
                      ref="packages"
                      v-model="form.packages"
                      :package_ids="form.packages"
                      :size="'medium'" 
                      :placeholder="$t('message.packages')"
                    >
                    </select-packages>
                  </el-form-item>
                </el-col>
              </el-col>
              <!-- end-col -->
                   
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item
                    :label="$t('message.categories')"
                    class="label_mini"
                    prop="categories"
                  >
                    <select-categories
                      ref="Categories"
                      v-model="form.categories"
                      :category_ids="form.categories"
                      :size="'medium'"
                      :data="JSON.parse(JSON.stringify(this.categories)).filter(item => item.has_child === false)"
                      :placeholder="$t('message.categories')"
                    >
                    </select-categories>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    
                    :label="columns.is_default.title"
                    class="label_mini"
                  >
                      <el-switch v-model="form.is_default" @change="defaultChange"></el-switch>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="columns.product_model_id.title"
                    class="label_mini"
                    prop="product_model_id"
                    v-if="!form.is_default"
                  >
                    <select-product-model
                      :size="'medium'"
                      :placeholder="columns.product_model_id.title"
                      :id="form.product_model_id"
                      v-model="form.product_model_id"
                    >
                    </select-product-model>
                  </el-form-item>
                </el-col>
                
                
                <el-col :span="24">
                    <el-upload class="upload-demo w-100" accept="image/jpeg, image/png" action="/" :limit="3" :on-change="updateImageList" :on-remove="handleRemove" list-type="picture-card" :file-list="imageList" :auto-upload="false">
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </el-col>

              </el-col>


            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCurrency from "@/components/inventory/select-currency";
import selectColor from "@/components/inventory/select-color";
import selectProductModel from "@/components/inventory/select-product-model";
import selectMeasurement from "@/components/inventory/select-measurement";
import selectCategories from "@/components/multiSelects/multi-select-categories";
import selectPackages from "@/components/multiSelects/multi-select-packages";

import _ from 'lodash';

export default {
  mixins: [form, drawer],
  components: {
    selectCurrency,
    selectMeasurement,
    selectCategories,
    selectProductModel,
    selectColor,
    selectPackages,
  },
  data() {
    return {
      imageList: [],
      updateImage: [],

    };
  },

  computed: {
    ...mapGetters({
      rules: "products/rules",
      model: "products/model",
      columns: "products/columns",
      categories: "categories/inventory",
    }),
  },
  methods: {
    ...mapActions({
      save: "products/store",
      empty: "products/empty",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {

          let formData = new FormData();

          for (const key in this.form) {
              if (key != 'is_default') {
                  if (this.form[key]) {
                      formData.append(key, this.form[key]);
                  }
                  else {
                      formData.append(key, '');
                  }
              } else if (key == 'is_default') {
                  formData.append(key, this.form[key]);
              }
          }

          if (_.size(this.updateImage) > 0) {
              for (const key in this.updateImage) {
                  if (this.updateImage.hasOwnProperty(key)) {
                      const element = this.updateImage[key];
                      formData.append(`images[${key}]`, element);
                  }
              }
          }
          this.form.images = formData;
          
          
          this.loadingButton = true;
          this.save(formData)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },

    updateImageList(file) {
        this.updateImage.push(file.raw);
    },
    handleRemove(file) {
        if (file.id) {
            this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                this.$alert(res);
            }).catch(err => {});
        } else {
            for (var i = 0; i < _.size(this.updateImage); i++) {
                if (this.updateImage[i].name === file.name) {
                    this.updateImage.splice(i, 1);
                }
            }

        }
    },

    defaultChange(e){
      this.form.is_default = e;
    },
    afterOpen() {
      this.$refs.buyPriceCurrency.selectedForCreate();
      this.$refs.sellingPriceCurrency.selectedForCreate();
    },
    afterLeave(){
      this.empty();
      this.imageList = []
    }
  },
};
</script>
